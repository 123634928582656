import React, { useEffect, useState } from 'react'
import css from "../css/Profile.module.css"
import { Link, useHistory } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import axios from 'axios';
// import { Link, useHistory } from 'react-router-dom';
// import Profile from './Profile';
// import { Image } from 'react-bootstrap';
import Rightcontainer from "../Components/Rightcontainer";
import Swal from 'sweetalert2';
import Header from '../Components/Header';
import '../Components/Component-css/Common.css';


const Profile1 = () => {
    const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.NODE_ENV;
    if (nodeMode === "development") {
      var baseUrl = beckendLocalApiUrl;
    } else {
      baseUrl = beckendLiveApiUrl;
    }
      const [show, setShow] = useState(false);

    const [referral, setCode] = useState('')
    const [Id, setId] = useState(null)
    const [profile, setProfile] = useState()
    const [portcss, setPortcss] = useState(css.active_tab)
    const [portcss1, setPortcss1] = useState(css.inactive_tab)
    const [crushcss, setCrushcss] = useState(true)
    const [holder_name,setHolder_name]= useState();
    const [account_number,setAccount_number]=useState();
    const [ifsc_code,setIfsc_code]=useState();
    const [upi_id,setUpi_id]=useState();
    const history = useHistory()
    const logout = () => {
        let access_token = localStorage.getItem("token")
        
        const headers = {
            Authorization: `Bearer ${access_token}`
        }
        
        axios.post(baseUrl+`logout`, {
            headers: headers
        }, { headers })
            .then((res) => {
                // setUser(res.data)
                localStorage.removeItem("token");
                //window.location.reload();
                history.push('/login')
                window.location.reload(true);
            }).catch((e) => {
                // alert(e.msg)
            })
    }
    const UserALL = () => {
        
        let access_token = localStorage.getItem("token")
        const headers = {
            Authorization: `Bearer ${access_token}`
        }
        axios.get(baseUrl+`me`, { headers })
            .then((res) => {
                setProfile(res.data)
                setId(res.data._id);
                TotalGame(res.data._id);
                setName(res.data.Name)
                setCode(res.data.referral)
                setHolder_name(res.data.holder_name);
                setAccount_number(res.data.account_number);
                setIfsc_code(res.data.ifsc_code);
                setUpi_id(res.data.upi_id);
            }).catch((e) => {
                // alert(e.msg)
            })
    }
    const [Name, setName] = useState()
    const UpdateProfile = async () => {
        const access_token = localStorage.getItem("token")
        const headers = {
            Authorization: `Bearer ${access_token}`
        }
        const data = await axios.patch(baseUrl+`user/edit`, {
            Name,
        }, { headers })
            .then((res) => {
                console.log(res.data)
                if(res.data=="User name already exist!")
                {
                    Swal.fire({
                        title: res.data,
                        icon: "error",
                        confirmButtonText: "OK",
                    });
                }
                else{
                    setName(res.data)
                    UserALL();
                }
            })
    }
    const updateBankDetails=async (e) => {
        e.preventDefault();
        const access_token = localStorage.getItem("token")
        const headers = {
            Authorization: `Bearer ${access_token}`
        }
        const data = await axios.patch(baseUrl+`user/edit`, {
           holder_name,account_number,ifsc_code,upi_id
        }, { headers })
            .then((res) => {
                console.log(res.data);
                if (res.status == 200) {
                    setShow(prev => !prev)
                    UserALL();
                    let message;
                    message = res.data.msg;
                    if (!(res.data.msg)) { 
                        message = 'something went wrong';
                    }
                    Swal.fire({
                        title: message,
                        icon: res.data.submit ? 'success' : 'error',
                        confirmButtonText: "OK",
                    });
                }
            })
    }
    const update_RefCode = async () => {
        const access_token = localStorage.getItem("token")
        const headers = {
            Authorization: `Bearer ${access_token}`
        }
        const data = await axios.patch(baseUrl+`user/edit`, {
            referral,
        }, { headers })
            .then((res) => {
                console.log(res.data);
                if (res.status == 200) {
                    UserALL();
                    let message;
                    message = res.data.msg;
                    if (!(res.data.msg)) {
                        message = 'Invalid referral Code';
                    }
                    Swal.fire({
                        title: message,
                        icon: res.data.submit ? 'success' : 'error',
                        confirmButtonText: "OK",
                    });
                }
            })
    }
    //// total game
    const [total, setTotal] = useState()
    const TotalGame = (Id) => {
        let access_token = localStorage.getItem("token")
        const headers = {
            Authorization: `Bearer ${access_token}`
        }

        axios.get(baseUrl+`total/user/all/${Id}`, { headers })
            .then((res) => {
                setTotal(res.data)

            }).catch((e) => {

            })
    }


    const [pic, setPic] = useState()
    const Result = async (file) => {
        if (file) {

            const access_token = localStorage.getItem("token")
            // // console.log(access_token)
            const headers = {
                Authorization: `Bearer ${access_token}`
            }

            const formData = new FormData();
            formData.append('avatar', file);


            // console.log(formData)
            await fetch(baseUrl+`users/me/avatar`, {
                method: "POST",
                body: formData,
                headers
            }).then((res) => {

                UserALL()
            })
        }


        // await axios.patch(baseUrl+`challange/result/${path}`,
        //   {
        //     status: status
        //   },
        //   { headers })
        //   .then((res) => {
        //     // getPost(res.data)
        //     // console.log(res.data);
        //     history.push("/landing")
        // }).catch((e) => {
        //     // console.log(e);
        //   })
    }
    //avatar

    useEffect(() => {
        UserALL()

    }, [])


    return (
        <>
        <Header user={profile} />
            <div className="leftContainer">

                <div>
                    <div className={`${css.my_profile_container} py-4`}>
                        <label>
                            <input className='d-none' type="file" onChange={(e) => Result(e.target.files[0])} accept="image/*" required />
                           
                            <picture >
                                {/* {image} */}
                                {profile && profile.avatar ? (<img height="80px" width="80px" src={baseUrl+`${profile && profile.avatar}`} alt="" style={{ borderRadius: "50px" }} />) : (<img height="100px" width="100px" src={process.env.PUBLIC_URL +`/user.png`} alt="" style={{ borderRadius: "50px" }} />)}
                            </picture>
                            
                        </label>
                        <span className={`${css.battle_input_header} mr-1`}>
                            Mobile No : {profile && profile.Phone}
                            <br></br>
                            Unique ID : <i className='text-danger'>{profile && profile.uniqueNumber}</i>
                        </span>
                        <div className={`text-bold my-3 ${portcss} font-weight-bold `} style={{ fontSize: '1em', color: '#ffffff'}} >
                            {profile && profile.Name}
                            <img className={`ml-2`} width="20px" src={process.env.PUBLIC_URL + '/Images/kd_ludo/icon-edit.png'} alt=""
                                onClick={() => {
                                    setPortcss(css.inactive_tab)
                                    setPortcss1(css.active_tab)
                                }}
                            />
                        </div>
                        <div className={`text-bold my-3 ${portcss1}`}>
                            <div className={`${css.MuiFormControl_root} ${css.MuiTextField_root}`} style={{ verticalAlign: 'bottom' }}>
                                <div className={`${css.MuiInputBase_root} ${css.MuiInput_root} ${css.MuiInput_underline} ${css.MuiInputBase_formControl} ${css.MuiInput_formControl}`}>
                                    <input  aria-invalid="false" type="text" maxLength={'20'} className={`${css.MuiInputBase_input} ${css.MuiInput_input}`} style={{color:'#fff'}} placeholder="Enter Username"
                                        value={Name}
                                        onChange={(e) => {
                                            setName(e.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                            {/*<img className="ml-2" width="20px" src={process.env.PUBLIC_URL + "/Images/select-blue-checkIcon.png"} alt=""
                                onClick={() => {
                                    setPortcss(css.active_tab)
                                    setPortcss1(css.inactive_tab)
                                    UpdateProfile(Id)
                                }}
                            />*/}
                            <i style={{fontSize:'1.5rem', paddingTop: '1rem', paddingBottom:'0', color:'#fff'}} onClick={() => {
                                    setPortcss(css.active_tab)
                                    setPortcss1(css.inactive_tab)
                                    UpdateProfile(Id)
                                }} class="fa-solid fa-check"></i>
                        </div>
                        {/* <Link className={`d-flex align-items-center profile_wallet`} style={{ width: '80%' }} to="/wallet">
                            <picture className="ml-4">
                                <img width="32px" src={process.env.PUBLIC_URL + "/Images/kd_ludo/wallet_.png"} alt="" />
                            </picture>
                            <div className={`ml-5 profile_wallet_text`}>
                                My Wallet
                            </div>
                        </Link> */}
                    </div>  
                </div>
                {/* <div className={css.divider_x}>
                </div> */}
                
                    
     
                
                <div className="profile_title">
                            Complete Profile
                        </div>
                <div className="profile_container">
                    <div className='profile_box'>

                       <div className='profile_sub_box'>
                        <Link className={`profile_wallet`} to="/wallet">
                            {/* <picture>
                                <img width="32px" src={process.env.PUBLIC_URL + "/Images/kd_ludo/wallet_.png"} alt="" />
                            </picture> */}
                            <i class="fa-solid fa-wallet"></i>
                            <div className={`profile_wallet_text`}>
                                My Wallet
                            </div>
                        </Link>
                        </div>

                        <div className='profile_sub_box'>
                            <Link className={`d-flex align-items-center  kyc_container`}  to={(profile && profile.verified === `unverified`) ? `/Kyc2` : `/Profile` }>
                                {/* <picture className="ml-4">
                                    <img width="32px" src={process.env.PUBLIC_URL + "/Images/kyc-icon-new.png"} alt="" className="" />
                                </picture> */}
                                <i class="fa-solid fa-id-card"></i>
                                <div className={`kyc_text`}>
                                    {profile && profile.verified === `unverified` ? 'Complete KYC' : profile && profile.verified === 'pending' ? 'In Process' : profile && profile.verified === 'verified' ? "Completed Kyc ✅" : "Completed Kyc ✅"}                                        
                                </div>
                            </Link>
                        </div>
                    </div>
 
                    
                    <div className='profile_box'>
                        <div className='profile_sub_box'>
                          {profile && !profile.referral && 
                          <div className="">
                            <div className={`refer_box ${css.refer_code_container}`}  >
                                <picture>
                                    {/* <img height="32px" width="32px" src={process.env.PUBLIC_URL + 'Images/Header/sreferEarn.png'} alt="" className="snip-img" /> */}
                                    <i class="fa-solid fa-user-plus" style={{fontSize:'1.5rem'}}></i>
                                </picture>  
                                <div className="">
                                    <div className={`refer_code`}>
                                        Use Refer Code
                                    </div>
                                    <div className='text-center'>
                                        
                                        <input type="text" className={css.MuiInputBase_input} aria-describedby="basic-addon1" style={{color:'#fff'}} value={referral} onChange={(e) => { setCode(e.target.value) }} />
                                        <button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => {
                                            update_RefCode()
                                        }}>
                                            {/* <img width="20px" src={process.env.PUBLIC_URL + "/Images/select-blue-checkIcon.png"} /> */}

                                            <i style={{fontSize:'1rem', paddingTop: '1rem', paddingBottom:'0'}} class="fa-solid fa-check"></i>
                                    
                                        </button>
                                    </div>
                                </div>
                            </div>
                             </div>
                          }
                          
                          { profile && profile.referral && 
                              <div className={`battle_played_box ${css.position_relative}`}>
                                 <picture>
                                    <i class="fa-solid fa-user-plus" style={{fontSize:'1.5rem'}}></i>
                                </picture>  
                                <div className="">
                                    <div className={`battle_played_text`}>
                                        Reffered By
                                    </div>
                                    <div className="snip-div text-center">
                                        <span className={css.moneyBox_text} style={{ fontSize: '1.5em', bottom: '-1px', color: '#ffffff' }}>
                                            {profile.referral && profile.referral}
                                        </span>
                                    </div>
                                </div>
                            </div>
                          }
                          
                          
                        </div>                

                        <div className='profile_sub_box' >
                            <div className={`won_cash_box  ${css.position_relative}`}>
                                {/* <picture>
                                    <img height="32px" width="32px" src={process.env.PUBLIC_URL + "/Images/global-cash-won-green-circular.png"} alt="" className="snip-img" />
                                </picture> */}
                                <i class="fa-solid fa-indian-rupee-sign"></i>
                                <div className="">
                                    <div className={`cash_won`}>
                                        Cash Won
                                    </div>


                                    <div className="text-center">
                                    <i class="fa-solid fa-rupee-sign" style={{fontSize:'1rem', paddingInline: '0'}}></i>
                                        <span className={css.moneyBox_text} style={{ fontSize: '1.5em', bottom: '-1px', left : '1rem', color: '#fff'}}>
                                            {profile && profile.wonAmount}
                                        </span>
                                    </div>
                                    {/* <span className={css.thin_divider_x}>
                                    </span> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='profile_box'>
                        <div className="profile_sub_box">
                            <div className={`battle_played_box ${css.position_relative}`}>
                                {/* <picture>
                                    <img height="32px" width="32px" src={process.env.PUBLIC_URL + "/Images/global-purple-battleIcon.png"} alt="" className="" />
                                </picture> */}
                                <i class="fa-solid fa-crown"></i>
                                <div className="">
                                    <div className={`battle_played_text`}>
                                        Battle Played
                                    </div>
                                    <div className="snip-div text-center">
                                        <span className={css.moneyBox_text} style={{ fontSize: '1.5em', bottom: '-1px', color: '#ffffff' }}>
                                            {total && total}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile_sub_box">
                            <div className={`refer_earning_box`}>
                                {/* <picture>
                                    <img height="32px" width="32px" src={process.env.PUBLIC_URL + "/Images/referral-signup-bonus-new.png"} alt="" className="snip-img" />
                                </picture> */}
                                <i class="fa-solid fa-trophy"></i>
                                <div className="">
                                    <div className={`referal_earning_text`}>
                                        Referral Earning
                                    </div>
                                    <div className="text-center">
                                        <span className={`${css.referal_earning_text}`} style={{ fontSize: '1.5em', bottom: '-1px', fontWeight: 'bold', color:`#ffffff` }}>
                                            {profile && profile.referral_earning}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* <div className={css.divider_x}>
                    </div> */}

                </div>



                <div className="logout_container">
                    <Link to="/login" className={`${css.center_xy} color: '#ffffff' `} onClick={(e) => logout(e)} style={{ fontSize: '1.2em', color: '#ffffff', marginBottom: '3rem', textTransform: 'uppercase'}}>
                        Log Out
                    </Link>
                </div>

            </div>
            <div className='rightContainer'>
                <Rightcontainer />
            </div>
            {/* <ModalProfile style3={
                profileModalstyle
            } Enter={Enter}/> */}
            <div className={css.kyc_select} id="profileModal" >
                <div className={css.overlay} />
                <div
                    className={`${css.box}`}
                    style={{
                        bottom: '0px',
                        position: 'absolute',
                    }}
                >
                    <div className={css.bg_white}>
                        <div className={`${css.header} ${css.cxy} ${css.flex_column}`}>
                            <picture>
                                <img
                                    height="80px"
                                    width="80px"
                                    src={process.env.PUBLIC_URL + "/Images/avatars/Avatar2.png"}
                                    alt=""
                                />
                            </picture>
                            <div className={`${css.header_text} mt-2`}>Choose Avatar</div>
                        </div>
                        <div className="mx-3 pb-3" style={{ paddingTop: "200px" }}>
                            <div className="row justify-content-between col-10 mx-auto">
                                <img
                                    height="50px"
                                    width="50px"
                                    src={process.env.PUBLIC_URL + "/Images/avatars/Avatar1.png"}
                                    alt=""
                                />
                                <img
                                    height="50px"
                                    width="50px"
                                    src={process.env.PUBLIC_URL + "/Images/avatars/Avatar2.png"}
                                    alt=""
                                />
                                <img
                                    height="50px"
                                    width="50px"
                                    src={process.env.PUBLIC_URL + "/Images/avatars/Avatar3.png"}
                                    alt=""
                                />
                                <img
                                    height="50px"
                                    width="50px"
                                    src={process.env.PUBLIC_URL + "/Images/avatars/Avatar4.png"}
                                    alt=""
                                />
                            </div>
                            <div className="row justify-content-between col-10 mx-auto mt-3">
                                <img
                                    height="50px"
                                    width="50px"
                                    src={process.env.PUBLIC_URL + "/Images/avatars/Avatar5.png"}
                                    alt=""
                                />
                                <img
                                    height="50px"
                                    width="50px"
                                    src={process.env.PUBLIC_URL + "/Images/avatars/Avatar6.png"}
                                    alt=""
                                />
                                <img
                                    height="50px"
                                    width="50px"
                                    src={process.env.PUBLIC_URL + "/Images/avatars/Avatar7.png"}
                                    alt=""
                                />
                                <img
                                    height="50px"
                                    width="50px"
                                    src={process.env.PUBLIC_URL + "/Images/avatars/Avatar8.png"}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Profile1;