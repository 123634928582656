import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Components/Header";
import Rightcontainer from "../Components/Rightcontainer";
import { UserFooterBar } from "./UserFooterBar";
const Gamerules = () => {

  const [userAllData, setUserAllData] = useState();
  return (
    <>

      <Header user={userAllData} />

      <div className="leftContainer" style={{ minHeight: '100vh' }}>

        <div className="m-3 py-5 pt-3 px-3">
          <h1><strong>Ludo Rules</strong></h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link href="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Ludo Rules
              </li>
            </ol>
          </nav>
          <div className="row">
            <div className="col-12">
              <h4><strong>Game Rules:</strong></h4>
              <ol className="rules-list">
                <li>
                  <strong>प्लेयर छोड़ने की स्थिति में परिणाम</strong>:
                  <ul>
                    <li>एक गोटी खुलने के बाद छोड़ता है: 25% नुकसान।</li>
                    <li>दो गोटी खुलने के बाद छोड़ता है: 50% नुकसान।</li>
                    <li>तीन गोटी खुलने के बाद छोड़ता है: पूरा जीत का अंक दूसरे प्लेयर को दिया जाएगा।</li>
                  </ul>
                </li>
                <li>
                  <strong>ऑटो एग्जिट की स्थिति में परिणाम</strong>:
                  <ul>
                    <li>यदि एक प्लेयर गेम में है और दूसरा प्लेयर नहीं है और गेम स्टार्ट हो गया है, और एक प्लेयर की गोटी ओपन हो गई है और दूसरा प्लेयर ऑटो एग्जिट है, तो ऑटो एग्जिट प्लेयर को पूरा लॉस दिया जाएगा।</li>
                  </ul>
                </li>
                <li>
                  <strong>नेटवर्क समस्या</strong>:
                  <ul>
                    <li>यूजर को अपने नेटवर्क की समस्या चेक करके खेलना चाहिए, यह उनकी स्वयं की जिम्मेदारी होगी।</li>
                  </ul>
                </li>
                <li>
                  <strong>रिजल्ट पोस्ट करने का समय</strong>:
                  <ul>
                    <li>गेम समाप्त होने के 15 मिनट के अंदर रिजल्ट डालना आवश्यक है। अन्यथा, सामने वाले प्लेयर के रिजल्ट के आधार पर गेम अपडेट कर दिया जाएगा चाहे आप जीते या हारे। इसके बाद में कोई बदलाव नहीं किया जाएगा।</li>
                  </ul>
                </li>
                <li>
                  <strong>गलत स्क्रीनशॉट पोस्ट करना</strong>:
                  <ul>
                    <li>विन होने के बाद यदि आप गलत स्क्रीनशॉट डालते हैं, तो गेम को सीधा कैंसिल कर दिया जाएगा। यदि आप स्क्रीनशॉट लेना भूल गए हैं, तो पहले लाइव चैट में एडमिन से संपर्क करें और उनके बताये अनुसार रिजल्ट पोस्ट करें।</li>
                  </ul>
                </li>
                <li>
                  <strong>गेम कैंसिल करने की स्थिति</strong>:
                  <ul>
                    <li>दोनों प्लेयर्स की गोटी घर से बाहर नहीं आई हो, तो लेफ्ट होकर गेम कैंसिल किया जा सकता है। कैंसिल प्रूफ करने के लिए वीडियो आवश्यक होगा।</li>
                    <li>यदि 'कैंसिल' रिजल्ट डालने के बाद गेम प्ले करके जीत जाते हैं, तो उसमें हमारी कोई जिम्मेदारी नहीं होगी। अतः गेम कैंसिल करने के बाद स्टार्ट न करें, अन्यथा वह कैंसिल ही माना जाएगा।</li>
                  </ul>
                </li>
                <li>
                  <strong>रिजल्ट पोस्ट करने के बाद बदलाव नहीं</strong>:
                  <ul>
                    <li>एक बार रिजल्ट डालने के बाद बदला नहीं जा सकता है, इसलिए सोच समझकर रिजल्ट पोस्ट करें। गलत रिजल्ट डालने पर गेम के 50% की पेनल्टी लगाई जाएगी, चाहे वह गलती से डाला हो या जानबूझ कर।</li>
                  </ul>
                </li>
              </ol>

              {/* <h4><strong>Commission Rates:</strong></h4>
                <ol className="rules-list">
                  <li>
                    <strong>कमीशन संरचना</strong>:
                    <ul>
                      <li>250₹ से कम की बैटल: बैटल राशि पर 10% कमीशन लिया जाएगा।</li>
                      <li>250₹ से 500₹ के बीच बैटल: फ्लैट 25₹ कमीशन लगेगा।</li>
                      <li>500₹ से ऊपर की बैटल: बैटल राशि पर 5% कमीशन लिया जाएगा।</li>
                    </ul>
                  </li>
                </ol> */}
            </div>
          </div>
        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </>
  );
};
export default Gamerules;