import React, { useEffect, useState } from 'react';
import css from '../css/Mywallet.module.css'
import Rightcontainer from '../Components/Rightcontainer'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios';
import Header from '../Components/Header';


const Mywallet = () => {
     const history = useHistory()
     const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
     const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
     const nodeMode = process.env.NODE_ENV;
     if (nodeMode === "development") {
       var baseUrl = beckendLocalApiUrl;
     } else {
       baseUrl = beckendLiveApiUrl;
     }
    let access_token = localStorage.getItem("token");
     access_token = localStorage.getItem("token")
    const [user, setUser] = useState()
    useEffect(() => {
         let access_token = localStorage.getItem('token');
        access_token = localStorage.getItem('token');
        if(!access_token)
        {
            window.location.reload()
            history.push("/login");
        }
        const headers = {
            Authorization: `Bearer ${access_token}`
        }
        axios.get(baseUrl+`me`, { headers })
            .then((res) => {
                setUser(res.data)
            }).catch((e) => {
                console.log(e)
                 if (e.response.status == 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('token');
                    window.location.reload()
                    history.push("/login")
                  }
            })
    }, [])


    return (
        <>
        <Header user={user} />
          <div className="leftContainer">
             
        <div className='main_section'
        style={{minHeight: "100vh", background:'var(--light-blue-color)'}}>

        <div className={`${css.divider_x} XXsnipcss_extracted_selector_selectionXX snipcss0-0-0-1 tether-target-attached-top tether-abutted tether-abutted-top tether-element-attached-top tether-element-attached-center tether-target-attached-center`}></div>
          <div className={`${css.wallet_section}`}>


          <div className="main_area order_history">
                <div style={{paddingTop: '1rem'}}>
                <Link className={`d-flex align-items-center ${css.profile_wallet} undefined`} to="/transaction-history">
                    <picture className="ml-4">
                        <img width="32px" src={process.env.PUBLIC_URL + '/Images/Header/order-history.png'} alt="" />
                    </picture>
                    <div className={`ml-2 ${css.mytext}`}>Order History</div>
                </Link>
                </div>
            </div>

            <div style={{display: 'flex', alignItems: 'center', gap: '1.4rem',
        marginTop: '2rem'}} className='walletB'>
                <div className={css.wallet_card} style={{border: "1px solid #fec637"}}>
                    <div className="d-flex text-center" 
                    style={{alignItems: 'center', justifyContent: 'center'}}>
                        <picture className="mr-1">
                            <img height="30px" width="30px" src={process.env.PUBLIC_URL + 'Images/LandingPage_img/global-rupeeIcon.gif'} alt=""/>
                        </picture>
                        
                        <span className="text-white" style={{fontSize:"1.3em",fontWeight:"900"}}>₹{user && user.Wallet_balance}</span>
                    </div>
                    
                    <div className="text-white text-uppercase text-center"
                    style={{fontSize:"1em",fontWeight:"600", fontFamily: 'Poppins'}}>Deposit Cash</div>
                    
                    <div className={`${css.my_text} text-center`}>Can be used to play Tournaments &amp; Battles.<br />Cannot be withdrawn to Paytm or Bank.</div>
                    
                    <Link to="/addcase"><button className= {`${css.walletCard_btn}`}>Add Cash</button></Link>
                </div>
                <div className={`${css.wallet_card2}`} style={{border: "1px solid #fec637"}}>
                    <div className="d-flex align-items-center" 
                    style={{alignItems: 'center', justifyContent: 'center'}}>                                  
                        <picture className="mr-1">
                        
                        <img height="30px" width="30px" src={process.env.PUBLIC_URL + 'Images/LandingPage_img/global-rupeeIcon.gif'} alt=""/></picture>
                            
                        <span className="text-white"style={{fontSize:"1.3em",fontWeight:"900"}}>₹{user && user.withdrawAmount}</span></div>
                        
                        <div className="text-white text-uppercase text-center" style={{fontSize:"1em",fontWeight:"600", fontFamily: 'Poppins'}}>Winning Cash</div>

                        <div className={`${css.my_text2} text-center`}>Can be withdrawn to Paytm or Bank. Can be used to play Tournaments &amp; Battles.</div>
                        
                        <Link to='/Withdrawopt'><button className= {`${css.walletCard_btn} d-flex justify-content-center align-items-center text-uppercase`}>Withdraw</button></Link>
                        
                </div>
            </div>
           
           
            </div>

        </div>


          



          </div>
          <div className="rightContainer">
              <div><Rightcontainer/></div>
          </div>
          </>
      )
}
export default Mywallet;