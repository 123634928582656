import React from "react";
import {useEffect ,useState} from "react";
import {useHistory } from 'react-router-dom'
import css from "../css/notification.module.css";
import Rightcontainer from "../Components/Rightcontainer";
import Header from '../Components/Header';
import axios from "axios";


const Notification = () => {

  const history = useHistory()
     const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
     const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
     const nodeMode = process.env.NODE_ENV;
     if (nodeMode === "development") {
       var baseUrl = beckendLocalApiUrl;
     } else {
       baseUrl = beckendLiveApiUrl;
     }
    let access_token = localStorage.getItem("token");
     access_token = localStorage.getItem("token")
    const [user, setUser] = useState()
    useEffect(() => {
         let access_token = localStorage.getItem('token');
        access_token = localStorage.getItem('token');
        if(!access_token)
        {
            window.location.reload()
            history.push("/login");
        }
        const headers = {
            Authorization: `Bearer ${access_token}`
        }
        axios.get(baseUrl+`me`, { headers })
            .then((res) => {
                setUser(res.data)
            }).catch((e) => {
                console.log(e)
                 if (e.response.status == 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('token');
                    window.location.reload()
                    history.push("/login")
                  }
            })
    }, [])

  return (
    <div>
      <Header user={user} />
      <div className="leftContainer" style={{height:'100vh'}}>
      
      <div
        className="cxy flex-column px-4 text-center"
        style={{ paddingTop: "30%" }}
      >
        <img
          src={process.env.PUBLIC_URL + "/Images/nonotification.png"}
          width="220px"
          className="snip-img"
          alt='no notification'
        />
        <div className={`${css.games_section_title} mt-4`} style={{ fontSize: "1.2em" }}>
          No notification yet!
        </div>
        <div
          className={`${css.games_section_headline} mt-2`}
          style={{ fontSize: "0.85em" }}
        >
          Seems like you haven’t done any activity yet
        </div>
      </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};

export default Notification;
